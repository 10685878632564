"use client";

import { useApolloClient } from "@apollo/client";
import { useMemo, useRef, useState } from "react";
import { useSearchParams } from "solito/navigation";
import clearState from "src/api/auth/helpers/clearState";
import GetClientProfileDocument from "src/api/graphql/__generated__/documents/GetClientProfileDocument";
import CollectDataField from "src/api/graphql/__generated__/enums/CollectDataField";
import UserType from "src/api/graphql/__generated__/enums/UserType";
import OpacityView from "src/components/OpacityView";
import isMaestro from "src/constants/isMaestro";
import log from "src/helpers/log";
import useHamburgerNav from "src/hooks/useHamburgerNav";
import useMountEffect from "src/hooks/useMountEffect";
import usePrefetchCallback from "src/hooks/usePrefetchCallback";
import useStabilizedCallback from "src/hooks/useStabilizedCallback";
import useGlobalStore from "src/stores/useGlobalStore";
import { AnimatePresence } from "src/swsh-native";
import toast from "src/swsh-native/toast";
import CloseModal from "./components/CloseModal";
import LoginContentWrapper from "./components/LoginContentWrapper";
import LoginLazyPrefetcher from "./components/LoginLazyPrefetcher";
import OnboardingPrefetchData from "./components/OnboardingPrefetchData";
import LoginContext from "./contexts/LoginContext";
import useCallbackRedirect from "./hooks/useCallbackRedirect";
import useLoginStore from "./hooks/useLoginStore";
import usePrePing from "./hooks/usePrePing";
import PrimaryLoginSpec from "./specs/PrimaryLoginSpec";
import { createLoginStore } from "./stores/loginStore";
import { LoginContainerProps } from "./types";

const LoginContainerContent = () => {
	useHamburgerNav(false);
	const spec = useLoginStore((state) => state.spec);
	const active = useLoginStore((state) => state.active);
	const activeScreen = useMemo(() => {
		const activeScreen = spec.screens.find((screen) => screen.variant === active);
		if (!activeScreen) {
			log.error({ active }, "No screen found for variant");
			toast({
				preset: "error",
				title: "Error logging in",
				message: "Please contact support",
			});
			return spec.screens[0]!;
		}
		return activeScreen;
	}, [active, spec.screens]);

	return (
		<LoginContentWrapper>
			<AnimatePresence>
				<OpacityView
					key={activeScreen.variant}
					style={{
						width: "100%",
						height: "100%",
						position: "absolute",
					}}
				>
					{<activeScreen.Component />}
				</OpacityView>
			</AnimatePresence>
		</LoginContentWrapper>
	);
};

const LoginContainer = ({
	spec = PrimaryLoginSpec,
	variant = "Full",
	title,
	collectData,
}: LoginContainerProps) => {
	const loggedIn = useGlobalStore((state) => state.loggedIn);
	const handleRedirect = useCallbackRedirect();
	const [closeModal, setCloseModal] = useState(false);
	const searchParams = useSearchParams<{
		join: string;
		collectData: string;
	}>();

	const isRedirecting = useRef(false);
	const handleLoginFlowComplete = useStabilizedCallback(async () => {
		if (isRedirecting.current) return;
		isRedirecting.current = true;
		switch (variant) {
			case "Full":
				handleRedirect({
					fallback: "/home",
				});
				return;
			case "Modal":
				setCloseModal(true);
				return;
		}
	}, [variant, handleRedirect]);

	const [loginStore] = useState(() => {
		const searchParamsCollectData = searchParams
			?.get("collectData")
			?.split(",")
			.filter((value) =>
				Object.values(CollectDataField).includes(value as CollectDataField),
			) as CollectDataField[];

		return createLoginStore({
			active: loggedIn
				? (spec.screens.find((screen) => screen.authenticated)?.variant ?? "Login")
				: "Login",
			spec,
			variant,
			handleLoginFlowComplete,
			title,
			collectData: collectData ?? searchParamsCollectData ?? [CollectDataField.Instagram],
		});
	});

	usePrefetchCallback();
	usePrePing();

	const apolloClient = useApolloClient();
	// Redirect if already completed with onboarding
	const [isReady, setIsReady] = useState(!loggedIn && !isMaestro);
	useMountEffect(() => {
		// Handling login redirect
		if (loggedIn) {
			(async () => {
				try {
					const {
						data: { getClientProfile: user },
					} = await apolloClient.query({
						query: GetClientProfileDocument,
						fetchPolicy: "cache-first",
					});
					if (user?.userType === UserType.Full) {
						handleLoginFlowComplete();
					} else {
						setIsReady(true);
					}
				} catch (err) {
					log.error({ err }, "Error getting client profile in LoginContainer");
					setIsReady(true);
				}
			})();
		} else if (isMaestro) {
			// Ensuring test users are fully logged out with no race conditions
			clearState(apolloClient).finally(() => {
				setIsReady(true);
			});
		}
	}, []);

	if (!isReady) return null;
	return (
		<>
			<LoginContext.Provider
				value={{
					store: loginStore,
				}}
			>
				<LoginContainerContent />
			</LoginContext.Provider>
			{/* Intentionally outside of context so that it breaks if the noop isn't working correctly */}
			<LoginLazyPrefetcher spec={spec} />
			{loggedIn && <OnboardingPrefetchData />}
			{closeModal && <CloseModal />}
		</>
	);
};
export default LoginContainer;
