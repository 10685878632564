import { useRef } from "react";
import { TextInputHandler } from "src/swsh-native";

/**
 * `react-native-screens` has a bug with focusing a text input immediately https://github.com/software-mansion/react-native-screens/issues/1637
 *
 * We resolve this by delaying our focus on iOS
 */
const useNavigationDelayedFocus = () => {
	const textInputRef = useRef<TextInputHandler>(null);

	return {
		textInputRef,
		autoFocus: true,
	};
};
export default useNavigationDelayedFocus;
