import { useApolloClient } from "@apollo/client";
import { useCallback, useState } from "react";
import GetClientProfileDocument from "src/api/graphql/__generated__/documents/GetClientProfileDocument";
import VerifyOtpDocument from "src/api/graphql/__generated__/documents/VerifyOtpDocument";
import {
	GetClientProfileFragmentFragment,
	GetClientProfileQuery,
} from "src/api/graphql/__generated__/graphql";
import onValidAuthorization from "src/api/graphql/onValidAuthorization";
import log from "src/helpers/log";
import toastError from "src/helpers/toastError";
import { getAuthStore } from "../stores/useAuthStore";

const useVerifyOtp = ({
	onVerifySuccess,
}: {
	onVerifySuccess?: (user: GetClientProfileFragmentFragment) => void;
} = {}) => {
	const apolloClient = useApolloClient();
	const [otpLoading, setOtpLoading] = useState(false);

	const handleVerifyOtp = useCallback(
		async (otp: string) => {
			try {
				setOtpLoading(true);
				const { requestId, clear } = getAuthStore();
				if (!requestId) {
					throw new Error("No requestId found");
				}
				const { data, errors } = await apolloClient.mutate({
					mutation: VerifyOtpDocument,
					variables: {
						input: {
							requestId,
							otp,
						},
					},
					errorPolicy: "all",
				});
				// Errors handling by ClientError default logic
				if (errors && errors.length > 0) return;
				if (!data || !data?.verifyOtp) {
					throw new Error("No data returned");
				}
				const { user, success } = data.verifyOtp;
				if (!success) {
					throw new Error("Mutation returned non-success");
				}
				if (!user) {
					throw new Error("No user returned");
				}

				clear();

				apolloClient.writeQuery({
					query: GetClientProfileDocument,
					data: {
						getClientProfile: {
							...user,
						} satisfies GetClientProfileQuery["getClientProfile"],
					},
				});

				await onValidAuthorization(apolloClient, user);
				onVerifySuccess?.(user);
			} catch (err) {
				log.error({ err }, "Error in handleRequestSmsOtp");
				toastError();
			} finally {
				setOtpLoading(false);
			}
		},
		[apolloClient, onVerifySuccess],
	);

	return {
		otpLoading,
		handleVerifyOtp,
		setOtpLoading,
	};
};
export default useVerifyOtp;
