import { View } from "react-native";
import useNavigationDelayedFocus from "src/hooks/useNavigationDelayedFocus";
import useStyle from "src/hooks/useStyle";
import { color } from "src/styles";
import { largeFont } from "src/styles/fonts";
import { paddingSmall } from "src/styles/spacing";
import { TextInput } from "src/swsh-native";
import { useLoginScreenContext } from "../../../contexts/LoginScreenContext";

const LoginPhoneNumberInput = () => {
	const {
		phoneNumberInputProps: {
			countryCodeInputRef,
			onCountryCodeChangeText,
			format,
			formattedPhoneNumber,
			onPhoneNumberChangeText,
		},
	} = useLoginScreenContext();
	const { theme } = useStyle();

	const { textInputRef, autoFocus } = useNavigationDelayedFocus();

	return (
		<View
			style={[
				{
					flexDirection: "row",
					maxWidth: "100%",
				},
			]}
		>
			<TextInput
				ref={countryCodeInputRef}
				clearTextOnFocus
				defaultValue="+1"
				keyboardType="number-pad"
				onChangeText={onCountryCodeChangeText}
				style={[
					largeFont,
					{
						maxWidth: 50,
						verticalAlign: "middle",
						textAlign: "center",
						padding: paddingSmall,
						borderRadius: 4,
						fontWeight: "600",
					},
				]}
			/>
			<TextInput
				ref={textInputRef}
				autoFocus={autoFocus}
				keyboardAppearance={theme === "Light" ? "light" : "dark"}
				textContentType="telephoneNumber"
				autoComplete="tel"
				keyboardType="number-pad"
				value={formattedPhoneNumber}
				onChangeText={onPhoneNumberChangeText}
				placeholder={format?.toLocaleUpperCase()}
				placeholderTextColor={color.InputPlaceholder[theme]}
				style={[
					largeFont,
					{
						maxWidth: 300,
						minWidth: 250,
						padding: paddingSmall,
						borderRadius: 4,
						fontWeight: "600",
					},
				]}
			/>
		</View>
	);
};
export default LoginPhoneNumberInput;
