import { useEffect, useState } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import OpacityView from "src/components/OpacityView";
import Touchable from "src/components/Touchable";
import { largeFont } from "src/styles/fonts";
import { paddingMedium, paddingSmall } from "src/styles/spacing";
import { Text, TextInput } from "src/swsh-native";

const OtpVerification = ({
	onChangeText,
	onResendRequested,
	containerStyle,
}: {
	onChangeText: (text: string) => void;
	onResendRequested: () => void;
	containerStyle?: StyleProp<ViewStyle>;
}) => {
	const [countdown, setCountdown] = useState<number | null>(null);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (countdown == null) {
			return;
		}

		if (countdown > 0) {
			timer = setTimeout(() => setCountdown(countdown - 1), 1000);
		} else {
			setCountdown(null);
			return;
		}
		return () => clearTimeout(timer);
	}, [countdown]);

	const resendCode = async () => {
		setCountdown(10);
		onResendRequested();
	};
	const [minTextWidth, setMinTextWidth] = useState<number | undefined>(undefined);
	useEffect(() => {
		if (countdown == null) {
			setMinTextWidth(undefined);
		}
	}, [countdown]);

	return (
		<OpacityView style={[containerStyle]}>
			<Text color="TextLight" small>
				Verification code
			</Text>
			<TextInput
				textContentType="oneTimeCode"
				autoComplete="one-time-code"
				keyboardType="number-pad"
				onChangeText={onChangeText}
				autoFocus
				placeholder={"XXXXXX"}
				style={[
					largeFont,
					{
						marginTop: paddingSmall,
						fontWeight: "600",
						maxWidth: 300,
						width: "100%",
						marginLeft: -paddingSmall,
						padding: paddingSmall,
						borderRadius: 4,
					},
				]}
			/>
			<View
				style={{
					flexDirection: "row",
					alignItems: "center",
					alignSelf: "flex-start",
					minWidth: minTextWidth,
					marginTop: paddingMedium,
				}}
			>
				<Text
					color="TextLight"
					xs
					onLayout={(event) => {
						if (!minTextWidth || event.nativeEvent.layout.width > minTextWidth) {
							setMinTextWidth(event.nativeEvent.layout.width);
						}
					}}
				>
					{"Didn't get it? "}
				</Text>
				<Touchable
					disabled={countdown != null}
					onPress={resendCode}
					style={{
						alignItems: "center",
					}}
				>
					<Text
						xs
						color={countdown == null ? "Text" : "TextLight"}
						semibold={countdown == null}
					>
						{countdown == null ? "Resend code" : `Resend code in ${countdown}s`}
					</Text>
				</Touchable>
			</View>
		</OpacityView>
	);
};
export default OtpVerification;
