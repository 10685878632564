import { useApolloClient } from "@apollo/client";
import { useCallback, useState } from "react";
import RequestEmailOtpDocument from "src/api/graphql/__generated__/documents/RequestEmailOtpDocument";
import log from "src/helpers/log";
import toastError from "src/helpers/toastError";
import { isEmailValid } from "src/shared/constants/textValidators";
import toast from "src/swsh-native/toast";
import { getAuthStore } from "../stores/useAuthStore";

const useRequestEmailOtp = () => {
	const apolloClient = useApolloClient();
	const [emailLoading, setEmailLoading] = useState(false);
	const [didSendEmail, setDidSendEmail] = useState(false);

	const handleRequestEmailOtp = useCallback(
		async (email: string) => {
			try {
				if (!email) return;
				if (!isEmailValid(email)) {
					toast({
						preset: "error",
						title: "Invalid email",
						message: "Please try again",
					});
					return;
				}
				setEmailLoading(true);
				const { bumpRetryCount, setRequestId, setEmail } = getAuthStore();
				const { data } = await apolloClient.mutate({
					mutation: RequestEmailOtpDocument,
					variables: {
						input: {
							email,
							retryCount: bumpRetryCount(),
						},
					},
				});
				if (!data || !data?.requestEmailOtp) {
					throw new Error("No data returned");
				}
				const { requestId, success } = data.requestEmailOtp;
				if (!success) {
					throw new Error("Mutation returned non-success");
				}

				setRequestId(requestId);
				setEmail(email);
				setDidSendEmail(true);
			} finally {
				setEmailLoading(false);
			}
		},
		[apolloClient],
	);

	const handleResendEmailOtp = useCallback(async () => {
		const { email } = getAuthStore();
		if (!email) {
			log.error("No email stored in store");
			toastError();
			return;
		}
		await handleRequestEmailOtp(email);
	}, [handleRequestEmailOtp]);

	return {
		emailLoading,
		didSendEmail,
		handleRequestEmailOtp,
		handleResendEmailOtp,
		setDidSendEmail,
		setEmailLoading,
	};
};
export default useRequestEmailOtp;
