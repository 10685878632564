import { useRouter } from "next/navigation";
import { useEffect, useRef } from "react";
import { UsePrefetch } from "./types";

const usePrefetch: UsePrefetch = (urls, options = {}) => {
	const router = useRouter();
	const didPrefetch = useRef(new Set<string>());
	useEffect(() => {
		const arrayUrls = (Array.isArray(urls) ? urls : [urls]).filter(Boolean) as string[];
		for (const url of arrayUrls) {
			if (didPrefetch.current.has(url)) {
				continue;
			}
			didPrefetch.current.add(url);
			router.prefetch(url);
		}
	}, [router, urls]);
};
export default usePrefetch;
