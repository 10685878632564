import { View } from "react-native";
import { paddingLarge, paddingXXXL } from "src/styles/spacing";
import { AnimatePresence } from "src/swsh-native";
import LoginInput from "./components/LoginInput";
import OtpVerification from "./components/OtpVerification";
import TermsOfService from "./components/TermsOfService";
import LoginScreenContext from "./contexts/LoginScreenContext";
import useLoginScreen from "./hooks/useLoginScreen";

const LoginScreen = () => {
	const { contextValue, onOtpChangeText, didSend, onResendRequested } = useLoginScreen();

	return (
		<LoginScreenContext.Provider value={contextValue}>
			<View
				testID="LoginScreen"
				style={{
					flex: 1,
					alignItems: "flex-start",
					alignSelf: "center",
					paddingHorizontal: paddingXXXL,
					width: "100%",
				}}
			>
				<LoginInput />
				<TermsOfService
					style={{
						marginTop: paddingLarge,
					}}
				/>
				<AnimatePresence>
					{didSend && (
						<OtpVerification
							onChangeText={onOtpChangeText}
							onResendRequested={onResendRequested}
							containerStyle={{
								marginTop: paddingLarge,
							}}
						/>
					)}
				</AnimatePresence>
			</View>
		</LoginScreenContext.Provider>
	);
};
export default LoginScreen;
