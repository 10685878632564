import { StyleProp, View, ViewStyle } from "react-native";
import OpacityView from "src/components/OpacityView";
import Touchable from "src/components/Touchable";
import { enumNever } from "src/shared/helpers/generalHelpers";
import { paddingMedium } from "src/styles/spacing";
import { Text } from "src/swsh-native";
import { useLoginScreenContext } from "../../contexts/LoginScreenContext";
import LoginEmailInput from "./components/LoginEmailInput";
import LoginPhoneNumberInput from "./components/LoginPhoneNumberInput";

const LoginInput = ({ style }: { style?: StyleProp<ViewStyle> }) => {
	const { inputVariant, setInputVariant } = useLoginScreenContext();
	return (
		<OpacityView
			key={inputVariant}
			style={[
				style,
				{
					width: "100%",
				},
			]}
		>
			{(() => {
				switch (inputVariant) {
					case "Email":
						return (
							<>
								<LoginEmailInput />
								<View
									style={{
										marginTop: paddingMedium,
										alignSelf: "flex-start",
										flexDirection: "row",
										alignItems: "center",
										flexWrap: "wrap",
									}}
								>
									<Text xs color="TextLight">
										No email?{" "}
									</Text>
									<Touchable
										onPress={() => setInputVariant("Phone")}
										containerStyle={{
											maxWidth: "100%",
										}}
										style={{
											alignItems: "center",
										}}
									>
										<Text semibold xs>
											Use phone number instead
										</Text>
									</Touchable>
								</View>
							</>
						);
					case "Phone":
						return (
							<>
								<LoginPhoneNumberInput />
								<View
									style={{
										marginTop: paddingMedium,
										alignSelf: "flex-start",
										flexDirection: "row",
										alignItems: "center",
										flexWrap: "wrap",
									}}
								>
									<Text xs color="TextLight">
										No phone?{" "}
									</Text>
									<Touchable
										onPress={() => setInputVariant("Email")}
										containerStyle={{
											maxWidth: "100%",
										}}
										style={{
											alignItems: "center",
										}}
									>
										<Text semibold xs>
											Use email instead
										</Text>
									</Touchable>
								</View>
							</>
						);
					default:
						return enumNever(inputVariant);
				}
			})()}
		</OpacityView>
	);
};
export default LoginInput;
