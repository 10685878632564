import { useContext } from "react";
import LoginContext from "../contexts/LoginContext";

const useRawLoginStore = () => {
	const ctx = useContext(LoginContext);
	if (!ctx) {
		throw new Error("useRawLoginStore must be used within a LoginStoreProvider");
	}
	return ctx.store;
};
export default useRawLoginStore;
