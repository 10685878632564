import OpacityView from "src/components/OpacityView";
import PrimaryLoginButtonCore from "./components/PrimaryLoginButtonCore";
import { PrimaryLoginButtonProps } from "./types";

const PrimaryLoginButton = (props: PrimaryLoginButtonProps) => {
	return (
		<OpacityView>
			<PrimaryLoginButtonCore {...props} />
		</OpacityView>
	);
};
export default PrimaryLoginButton;
