import { track } from "@amplitude/analytics-react-native";
import { ReactNode, useMemo } from "react";
import { View } from "react-native";
import { amp } from "src/api/amplitude";
import GlowProgressBar from "src/components/GlowProgressBar";
import KeyboardDismissView from "src/components/KeyboardDismissView";
import Touchable from "src/components/Touchable";
import isWeb from "src/constants/isWeb";
import native from "src/constants/native";
import useDevice from "src/hooks/useDevice";
import useDim from "src/hooks/useDim";
import IconLogo from "src/icons/IconLogo";
import {
	paddingLarge,
	paddingSmall,
	paddingSmallSpacer,
	paddingXL,
	paddingXXL,
	paddingXXXL,
} from "src/styles/spacing";
import { Link, Text, useSafeArea } from "src/swsh-native";
import useGetHelp from "../hooks/useGetHelp";
import useLoginStore from "../hooks/useLoginStore";
import useRawLoginStore from "../hooks/useRawLoginStore";
import FloatingLoginButtons from "./FloatingLoginButtons/FloatingLoginButtons";

const LoginContentWrapper = ({ children }: { children: ReactNode }) => {
	const title = useLoginStore((state) => state.title);
	const spec = useLoginStore((state) => state.spec);
	const active = useLoginStore((state) => state.active);
	const variant = useLoginStore((state) => state.variant);
	const { device } = useDevice();
	const getHelp = useGetHelp();
	const store = useRawLoginStore();
	const safeArea = useSafeArea();

	const { numHighlighted, numTotal } = useMemo(() => {
		const numHighlighted = spec.screens.findIndex((screen) => screen.variant === active) + 1;
		return {
			numHighlighted,
			numTotal: spec.screens.length,
		};
	}, [active, spec.screens]);
	const isBig = device === "desktop" && variant === "Full";
	const dim = useDim();

	return (
		<KeyboardDismissView
			containerStyle={[
				{
					minWidth: Math.min(420, dim.width),
					flex: 1,
				},
				isWeb
					? {
							// Fixes behavior for extremely short screens
							// overflowX doesn't work reliably on native and scroll doesn't matter on native anyways
							overflowX: "hidden",
							overflowY: "scroll",
						}
					: { overflow: "hidden" },
			]}
			style={{
				marginTop: safeArea.top ? safeArea.top + paddingLarge : paddingXL,
				marginBottom: safeArea.bottom ? safeArea.bottom + paddingSmall : paddingLarge,
				flex: 1,
			}}
		>
			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
					paddingHorizontal: isBig ? paddingSmallSpacer : paddingXXXL,
				}}
			>
				<View>
					{isBig && (
						<Link href="/">
							<IconLogo size={52} />
						</Link>
					)}
				</View>
				<Touchable onPress={getHelp}>
					<Text color="TextLight">Help</Text>
				</Touchable>
			</View>
			<View
				style={[
					{
						flex: 1,
					},
					isBig && {
						alignItems: "center",
						justifyContent: "center",
					},
				]}
			>
				<View
					style={[
						!isBig && {
							flex: 1,
						},
					]}
				>
					<Text
						testID="LoginTitle"
						semibold
						style={{
							alignSelf: "center",
							marginTop: paddingLarge,
						}}
					>
						{title}
					</Text>
					<GlowProgressBar
						numHighlighted={numHighlighted}
						numTotal={numTotal}
						width={250}
						containerStyle={{
							alignSelf: "center",
						}}
						onCompletePressed={(index) => {
							const screen = spec.screens[index];
							const curScreen = spec.screens.find(
								(screen) => screen.variant === active,
							);
							if (!screen) return;
							if (!curScreen) return;
							if (screen.authenticated && curScreen.authenticated) {
								track(amp.onboarding.jump, {
									from: curScreen.variant,
									to: screen.variant,
								});
								store.setState({
									active: screen.variant,
								});
							}
						}}
					/>
					<View
						style={{
							minHeight: 350,
							flex: 1,
							marginTop: native ? paddingXXL : paddingLarge,
							marginBottom: paddingLarge,
						}}
					>
						{children}
					</View>
					<FloatingLoginButtons />
				</View>
			</View>
		</KeyboardDismissView>
	);
};
export default LoginContentWrapper;
