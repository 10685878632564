import { useApolloClient } from "@apollo/client";
import { useCallback, useState } from "react";
import RequestSmsOtpDocument from "src/api/graphql/__generated__/documents/RequestSmsOtpDocument";
import log from "src/helpers/log";
import toastError from "src/helpers/toastError";
import { getAuthStore } from "../stores/useAuthStore";

const useRequestSmsOtp = ({ onEmailRedirect }: { onEmailRedirect?: () => void } = {}) => {
	const apolloClient = useApolloClient();
	const [phoneNumberLoading, setPhoneNumberLoading] = useState(false);
	const [didSendPhoneNumber, setDidSendPhoneNumber] = useState(false);

	const handleRequestSmsOtp = useCallback(
		async (phoneNumber: string) => {
			try {
				setPhoneNumberLoading(true);
				const { bumpRetryCount, setRequestId, setPhoneNumber } = getAuthStore();
				const { data, errors } = await apolloClient.mutate({
					mutation: RequestSmsOtpDocument,
					variables: {
						input: {
							phoneNumber,
							retryCount: bumpRetryCount(),
						},
					},
					errorPolicy: "all",
				});
				// Errors handling by ClientError default logic
				if (errors && errors.length > 0) {
					if (errors.some((error) => error.extensions?.action === "EMAIL_REDIRECT")) {
						onEmailRedirect?.();
						return;
					}
					return;
				}
				if (!data || !data?.requestSmsOtp) {
					throw new Error("No data returned");
				}
				const { requestId, success } = data.requestSmsOtp;
				if (!success) {
					throw new Error("Mutation returned non-success");
				}

				setRequestId(requestId);
				setPhoneNumber(phoneNumber);
				setDidSendPhoneNumber(true);
			} catch (err) {
				log.error({ err }, "Error in handleRequestSmsOtp");
				toastError();
			} finally {
				setPhoneNumberLoading(false);
			}
		},
		[apolloClient, onEmailRedirect],
	);

	const handleResendSmsOtp = useCallback(async () => {
		const { phoneNumber } = getAuthStore();
		if (!phoneNumber) {
			log.error("No phone number stored in store");
			toastError();
			return;
		}
		await handleRequestSmsOtp(phoneNumber);
	}, [handleRequestSmsOtp]);

	return {
		phoneNumberLoading,
		didSendPhoneNumber,
		handleRequestSmsOtp,
		handleResendSmsOtp,
	};
};
export default useRequestSmsOtp;
