import { View } from "react-native";
import { KeyboardDismissViewProps } from "./types";

const KeyboardDismissView = ({ style, containerStyle, children }: KeyboardDismissViewProps) => {
	return (
		<View style={containerStyle}>
			<View style={style}>{children}</View>
		</View>
	);
};
export default KeyboardDismissView;
