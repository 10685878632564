import { useQuery } from "@apollo/client";
import GetPublicAssetsForHomeSuggestionDocument from "src/api/graphql/__generated__/documents/GetPublicAssetsForHomeSuggestionDocument";
import PublicAssetVariant from "src/api/graphql/__generated__/enums/PublicAssetVariant";
import useHomeFeed from "./useHomeFeed";
import useHomeGatherings from "./useHomeGatherings";

/**
 * Hook used by external components to prefetch data for the home screen
 */
const usePrefetchHomeData = () => {
	useQuery(GetPublicAssetsForHomeSuggestionDocument, {
		variables: {
			input: {
				variant: PublicAssetVariant.GatheringThumbnail,
			},
		},
		fetchPolicy: "cache-first",
	});
	useHomeFeed();
	useHomeGatherings({ numColumns: 2 });
};

export default usePrefetchHomeData;
