import CollectDataField from "src/api/graphql/__generated__/enums/CollectDataField";
import { createStore } from "zustand";
import { LoginScreenSpec, LoginScreenVariant, RenderContentWrapperProps } from "../types";

export interface LoginStore {
	variant: "Full" | "Modal";
	active: LoginScreenVariant;
	spec: LoginScreenSpec;
	title: string;
	loading: boolean;
	collectData: CollectDataField[];
	screenOverrides: Partial<
		Record<
			LoginScreenVariant,
			{
				renderContentWrapperProps: RenderContentWrapperProps;
			}
		>
	>;
	registerScreenOverride: (variant: LoginScreenVariant, props: RenderContentWrapperProps) => void;
	deregisterScreenOverride: (variant: LoginScreenVariant) => void;
	handleLoginFlowComplete: () => void;
}

export const createLoginStore = (
	initialState: Pick<
		LoginStore,
		"active" | "spec" | "variant" | "handleLoginFlowComplete" | "collectData"
	> &
		Partial<Pick<LoginStore, "title">>,
) => {
	return createStore<LoginStore>()((set, get) => ({
		...initialState,
		title: initialState.title ?? "Join swsh",
		screenOverrides: {},
		loading: false,
		registerScreenOverride: (variant, props) => {
			set((state) => {
				return {
					screenOverrides: {
						...state.screenOverrides,
						[variant]: {
							renderContentWrapperProps: props,
						},
					},
				};
			});
		},
		deregisterScreenOverride: (variant) => {
			set((state) => {
				delete state.screenOverrides[variant];
				return {
					screenOverrides: {
						...state.screenOverrides,
					},
				};
			});
		},
	}));
};
