import { useEffect } from "react";
import PingDocument from "src/api/graphql/__generated__/documents/PingDocument";
import { useAsyncQuery } from "src/api/graphql/helper";
import toast from "src/swsh-native/toast";

const usePrePing = async () => {
	// If for whatever reason a user's credentials were lost in local state (generally on web), the response from ping contains an x-user-id header that will be used to re-authenticate the user
	const ping = useAsyncQuery(PingDocument);
	useEffect(() => {
		(async () => {
			const { data } = await ping({});
			if (!data?.ping?.success) {
				toast({
					preset: "error",
					title: "Network Error",
					message: "Something went wrong contacting our server",
				});
			}
		})();
	}, [ping]);
};
export default usePrePing;
