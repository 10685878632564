import Animated, { interpolate, useAnimatedStyle } from "react-native-reanimated";
import { GlowProgressBarSegmentProps } from "./types";

const GlowProgressBarSegment = ({
	progressSV,
	containerStyle,
	rangeMin = 0,
	rangeMax = 1,
}: GlowProgressBarSegmentProps) => {
	const animatedStyle = useAnimatedStyle(
		() => ({
			left: `${interpolate(progressSV.value, [rangeMin, rangeMax], [-100, 0], {
				extrapolateLeft: "clamp",
				extrapolateRight: "clamp",
			})}%`,
		}),
		[progressSV, rangeMax, rangeMin],
	);

	return (
		<Animated.View
			style={[
				{
					flex: 1,
					height: 4,
					minHeight: 4,
					maxHeight: 4,
					borderRadius: 4,
					overflow: "hidden",
					backgroundColor: "#FFFFFF40",
				},
				containerStyle,
			]}
		>
			<Animated.View
				style={[
					{
						position: "absolute",
						width: "100%",
						height: "100%",
						backgroundColor: "#FFFFFF80",
					},
					animatedStyle,
				]}
			/>
		</Animated.View>
	);
};
export default GlowProgressBarSegment;
