import RoundedButton from "src/components/RoundedButton";
import { PrimaryLoginButtonProps } from "../types";

const PrimaryLoginButtonCore = ({
	disabled,
	loading,
	onPress,
	overrideText,
}: PrimaryLoginButtonProps) => {
	return (
		<RoundedButton
			testID="PrimaryLoginButton"
			disabled={disabled}
			loading={loading}
			colorVariant="Primary"
			sizeVariant="Large"
			onPress={onPress}
		>
			{overrideText ?? "Next"}
		</RoundedButton>
	);
};
export default PrimaryLoginButtonCore;
