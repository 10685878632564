import { useEffect } from "react";
import { useInsideModal } from "src/modals";

const CloseModal = () => {
	const { closeSelf } = useInsideModal();
	useEffect(() => {
		closeSelf();
	}, [closeSelf]);
	return null;
};
export default CloseModal;
