import { track } from "@amplitude/analytics-react-native";
import { useCallback } from "react";
import forceLogout from "src/api/auth/forceLogout";
import OnboardingHelpDocument from "src/api/graphql/__generated__/documents/OnboardingHelpDocument";
import { useAsyncMutation } from "src/api/graphql/helper";
import { getGlobalStore } from "src/stores/useGlobalStore";
import { alert, prompt } from "src/swsh-native";
import toast from "src/swsh-native/toast";

const useGetHelp = () => {
	const onboardingHelp = useAsyncMutation(OnboardingHelpDocument);
	const getHelp = useCallback(async () => {
		try {
			const buttons: NonNullable<Parameters<typeof prompt>[0]["buttons"]> = [];
			track("onboarding_getHelp_initiate");
			buttons.push({
				style: "cancel",
				text: "Cancel",
				onPress: () => {
					track("onboarding_getHelp_cancel");
				},
			});
			const { loggedIn } = getGlobalStore();
			if (loggedIn) {
				buttons.push({
					style: "destructive",
					text: "Log out",
					onPress: () => {
						track("onboarding_getHelp_logout");
						forceLogout();
					},
				});
			}
			buttons.push({
				style: "default",
				isPreferred: true,
				text: "Submit",
				onPress: async (text) => {
					track("onboarding_getHelp_submit");
					const { data } = await onboardingHelp({
						variables: {
							input: {
								content: text ?? "[undefined]",
							},
						},
					});
					if (data?.onboardingHelp?.success === true) {
						if (
							data?.onboardingHelp?.suggestion &&
							data.onboardingHelp.suggestion.message
						) {
							alert({
								title:
									data.onboardingHelp.suggestion.title ?? "Running into issues?",
								message: data.onboardingHelp.suggestion.message,
								buttons: [
									{
										text: "Okay",
									},
								],
							});
						}
						toast({
							preset: "done",
							title: "Message sent!",
							message: "We'll resolve your issue as soon as possible",
						});
					} else {
						toast({
							preset: "error",
							title: "Something went wrong",
							message: "Email contact@joinswsh.com for help",
						});
					}
				},
			});
			prompt({
				title: "Get Help",
				message:
					"What's going on? Please describe your issue and include your email address so we can get back to you",
				buttons,
			});
		} catch (err) {
			toast({
				preset: "error",
				title: "Something went wrong",
				message: "Email contact@joinswsh.com for help",
			});
		}
	}, [onboardingHelp]);
	return getHelp;
};

export default useGetHelp;
