import lazy from "src/swsh-native/lazy";
import LoginScreenWrapper from "../../screens/LoginScreen/LoginScreenWrapper";
import { LoginScreenSpec } from "../../types";

const PrimaryLoginSpec = {
	screens: [
		{
			variant: "Login",
			Component: LoginScreenWrapper,
		},
		{
			variant: "BasicInfoAndSetUp",
			authenticated: true,
			Component: lazy(
				() => import("../../screens/OnboardingBasicInfo/OnboardingBasicInfoWrapper"),
			),
		},
		{
			variant: "ProfilePicture",
			authenticated: true,
			lazyLoadNextButton: true,
			Component: lazy(
				() =>
					import(
						"../../screens/OnboardingProfilePicture/OnboardingProfilePictureWrapper"
					),
			),
		},
	],
} satisfies LoginScreenSpec;

export default PrimaryLoginSpec;
