import { track } from "@amplitude/analytics-react-native";
import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";
import { amp } from "src/api/amplitude";
import CompleteOnboardingDocument from "src/api/graphql/__generated__/documents/CompleteOnboardingDocument";
import CompleteOnboardingUserFragmentFragmentDoc from "src/api/graphql/__generated__/documents/CompleteOnboardingUserFragmentFragmentDoc";
import log from "src/helpers/log";
import { setGlobalStore } from "src/stores/useGlobalStore";
import { LoginScreenVariant } from "../types";
import useRawLoginStore from "./useRawLoginStore";

const useLoginContainerHooks = (variant: LoginScreenVariant) => {
	const store = useRawLoginStore();
	const apolloClient = useApolloClient();

	const loginNavigate = useCallback(
		async (
			target: "Next" | "EarlyComplete",
			{
				promises = [],
			}: {
				/**
				 * Promises to finish resolving for completing onboarding
				 */
				promises?: Promise<any>[];
			} = {},
		) => {
			const { spec, handleLoginFlowComplete } = store.getState();
			const curIndex = spec.screens.findIndex((item) => item.variant === variant);
			if (target === "EarlyComplete" || curIndex === spec.screens.length - 1) {
				if (target !== "EarlyComplete") {
					track(amp.onboarding.complete, {
						from: variant,
						to: target,
					});
					const { data } = await apolloClient.mutate({
						mutation: CompleteOnboardingDocument,
						fetchPolicy: promises.length > 0 ? "no-cache" : "network-only",
					});
					if (promises.length > 0) {
						await Promise.all(promises);
						if (data?.completeOnboarding) {
							apolloClient.writeFragment({
								fragment: CompleteOnboardingUserFragmentFragmentDoc,
								data: data.completeOnboarding,
							});
						}
					}
					setGlobalStore({
						loggedIn: true,
						userType: data?.completeOnboarding?.userType,
						clientId: data?.completeOnboarding?.userId,
					});
				}
				store.setState({
					loading: true,
				});
				handleLoginFlowComplete();
			} else if (target === "Next") {
				if (curIndex === -1) {
					log.error("Could not find current screen in screens array");
					return;
				}
				const nextIndex = curIndex + 1;
				const nextScreen = spec.screens[nextIndex];
				if (nextIndex >= spec.screens.length || !nextScreen) {
					log.error("Could not find next screen in screens array");
					return;
				}
				track(amp.onboarding.continue, {
					from: variant,
					to: nextScreen.variant,
				});
				store.setState({
					active: nextScreen.variant,
				});
			}
		},
		[apolloClient, store, variant],
	);

	return { loginNavigate };
};
export default useLoginContainerHooks;
