import useStyle from "src/hooks/useStyle";
import { color } from "src/styles";
import { largeFont } from "src/styles/fonts";
import { paddingSmall } from "src/styles/spacing";
import { TextInput } from "src/swsh-native";
import { useLoginScreenContext } from "../../../contexts/LoginScreenContext";

const LoginEmailInput = () => {
	const {
		emailInputProps: { emailValueRef, onSubmit, onDebouncedEmailChange },
	} = useLoginScreenContext();
	const { theme } = useStyle();

	return (
		<TextInput
			keyboardAppearance={theme === "Light" ? "light" : "dark"}
			textContentType="emailAddress"
			autoComplete="email"
			keyboardType="email-address"
			autoCapitalize="none"
			autoCorrect={false}
			onChangeText={(text) => {
				emailValueRef.current = text;
			}}
			onDebouncedChangeText={onDebouncedEmailChange}
			autoFocus
			placeholder="Email address"
			placeholderTextColor={color.InputPlaceholder[theme]}
			onSubmitEditing={onSubmit}
			style={[
				largeFont,
				{
					maxWidth: 300,
					minWidth: 250,
					padding: paddingSmall,
					borderRadius: 4,
					fontWeight: "600",
				},
			]}
		/>
	);
};
export default LoginEmailInput;
