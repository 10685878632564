import { useCallback, useMemo, useRef, useState } from "react";
import { useSearchParams } from "solito/navigation";
import useRequestEmailOtp from "src/api/auth/hooks/useRequestEmailOtp";
import useRequestSmsOtp from "src/api/auth/hooks/useRequestSmsOtp";
import useVerifyOtp from "src/api/auth/hooks/useVerifyOtp";
import { getAuthStore } from "src/api/auth/stores/useAuthStore";
import UserType from "src/api/graphql/__generated__/enums/UserType";
import useStabilizedCallback from "src/hooks/useStabilizedCallback";
import { enumNever } from "src/shared/helpers/generalHelpers";
import useLoginContainerHooks from "../../../hooks/useLoginContainerHooks";
import useLoginContainerRegistration from "../../../hooks/useLoginContainerRegistration";
import { LoginScreenContextType } from "../contexts/LoginScreenContext";
import useOtpInput from "./useOtpInput";
import usePhoneNumberInput from "./usePhoneNumberInput/usePhoneNumberInput";

const useLoginScreen = () => {
	const { loginNavigate } = useLoginContainerHooks("Login");
	const searchParams = useSearchParams<{ method?: string }>();
	const [inputVariant, setInputVariant] = useState<"Phone" | "Email">(() =>
		searchParams?.get("method") === "Email" ? "Email" : "Phone",
	);
	const emailValueRef = useRef("");
	const {
		didSendEmail,
		emailLoading,
		handleRequestEmailOtp,
		handleResendEmailOtp,
		setDidSendEmail,
		setEmailLoading,
	} = useRequestEmailOtp();
	const phoneNumberValueRef = useRef("");
	const { didSendPhoneNumber, handleRequestSmsOtp, handleResendSmsOtp, phoneNumberLoading } =
		useRequestSmsOtp({
			onEmailRedirect: () => {
				setInputVariant("Email");
			},
		});

	const { isLikelyValid: phoneNumberLikelyValid, ...phoneNumberInputProps } = usePhoneNumberInput(
		{
			phoneNumberValueRef: phoneNumberValueRef,
			onValidPhoneNumber: handleRequestSmsOtp,
		},
	);
	const otpValueRef = useRef("");
	const { handleVerifyOtp, otpLoading, setOtpLoading } = useVerifyOtp({
		onVerifySuccess: (user) => {
			if (user.userType === UserType.Full) {
				loginNavigate("EarlyComplete");
			} else {
				loginNavigate("Next");
			}
		},
	});
	const { isValid: isOtpValid, onOtpChangeText } = useOtpInput({
		otpValueRef: otpValueRef,
		onValidOtp: handleVerifyOtp,
	});

	const handleDebouncedEmailChange = useCallback(
		(email: string) => {
			if (email !== getAuthStore().email && email === emailValueRef.current) {
				setDidSendEmail(false);
				setEmailLoading(false);
				setOtpLoading(false);
			}
		},
		[setDidSendEmail, setEmailLoading, setOtpLoading],
	);

	const handlePress = useStabilizedCallback(async () => {
		if (isOtpValid) {
			await handleVerifyOtp(otpValueRef.current);
		} else {
			switch (inputVariant) {
				case "Phone":
					await handleRequestSmsOtp(phoneNumberValueRef.current);
					return;
				case "Email":
					await handleRequestEmailOtp(emailValueRef.current);
					return;
			}
		}
	}, [handleRequestEmailOtp, handleRequestSmsOtp, handleVerifyOtp, inputVariant, isOtpValid]);
	const { loading, didSend, onResendRequested } = useMemo<{
		loading: boolean;
		didSend: boolean;
		onResendRequested: () => void;
	}>(() => {
		switch (inputVariant) {
			case "Email":
				return {
					loading: emailLoading || otpLoading,
					didSend: didSendEmail,
					onResendRequested: handleResendEmailOtp,
				};
			case "Phone":
				return {
					loading: phoneNumberLoading || otpLoading,
					didSend: didSendPhoneNumber,
					onResendRequested: handleResendSmsOtp,
				};
			default:
				return enumNever(inputVariant);
		}
	}, [
		didSendEmail,
		didSendPhoneNumber,
		emailLoading,
		handleResendEmailOtp,
		handleResendSmsOtp,
		inputVariant,
		otpLoading,
		phoneNumberLoading,
	]);

	useLoginContainerRegistration("Login", {
		wrapperProps: {
			primaryButton: {
				onPress: handlePress,
				// If we haven't sent yet we want to manage the loading process fully manually
				disableLocalLoading: true,
				loading: loading,
				disabled: !didSend
					? inputVariant === "Phone"
						? !phoneNumberLikelyValid
						: false
					: !isOtpValid,
			},
		},
	});

	return {
		inputVariant,
		contextValue: {
			inputVariant,
			setInputVariant,
			phoneNumberInputProps,
			emailInputProps: {
				emailValueRef,
				onDebouncedEmailChange: handleDebouncedEmailChange,
				onSubmit: () => handleRequestEmailOtp(emailValueRef.current),
			},
		} satisfies LoginScreenContextType,
		onOtpChangeText,
		didSend,
		onResendRequested,
	};
};
export default useLoginScreen;
