import { ApolloError } from "@apollo/client";
import { memo, useEffect, useMemo, useState } from "react";
import { View } from "react-native";
import OpacityView from "src/components/OpacityView";
import RoundedButton from "src/components/RoundedButton";
import log from "src/helpers/log";
import { paddingLarge } from "src/styles/spacing";
import useLoginContainerHooks from "../../hooks/useLoginContainerHooks";
import useLoginStore from "../../hooks/useLoginStore";
import PrimaryLoginButton from "./components/PrimaryLoginButton/PrimaryLoginButton";

const FloatingLoginButtons = () => {
	const active = useLoginStore((state) => state.active);
	const spec = useLoginStore((state) => state.spec);
	const overrides = useLoginStore((state) => state.screenOverrides[active]);
	const { primaryButton, additionalButtons } = overrides?.renderContentWrapperProps ?? {};
	const externalLoading = useLoginStore((state) => state.loading);
	const [localLoading, setLocalLoading] = useState(false);
	const { loginNavigate } = useLoginContainerHooks(active);
	const { lazyLoadNextButton } = useMemo(() => {
		const curActive = spec.screens.find((screen) => screen.variant === active);
		return {
			lazyLoadNextButton: curActive?.lazyLoadNextButton ?? false,
		};
	}, [active, spec.screens]);
	useEffect(() => {
		active;
		// If lazy loading next button is enabled, we let the next useEffect handle it
		if (!lazyLoadNextButton) {
			setLocalLoading(false);
		}
	}, [active, lazyLoadNextButton]);
	useEffect(() => {
		primaryButton?.text;
		// Handles lazy loaded next buttons
		setLocalLoading(false);
	}, [primaryButton?.text]);

	return (
		<View
			style={{
				width: "100%",
				alignItems: "center",
				rowGap: paddingLarge,
			}}
		>
			<PrimaryLoginButton
				disabled={primaryButton?.disabled}
				loading={
					primaryButton?.loading ||
					(!primaryButton?.disableLocalLoading && localLoading) ||
					externalLoading
				}
				onPress={async () => {
					setLocalLoading(true);
					if (primaryButton?.onPress) {
						try {
							const res = await primaryButton?.onPress?.();
							if (res && res?.setLoading != null) {
								setLocalLoading(res.setLoading);
							}
						} catch (err) {
							if (!(err instanceof ApolloError)) {
								log.error({ err }, "Error in primaryButton.onPress");
							}
							setLocalLoading(false);
						}
					} else {
						loginNavigate("Next");
					}
				}}
				overrideText={primaryButton?.text}
			/>

			{additionalButtons?.map((button, index) => (
				<OpacityView key={index} exiting={false}>
					<RoundedButton
						testID="AdditionalButton"
						onPress={async () => {
							setLocalLoading(true);
							try {
								await button.onPress?.();
							} finally {
								setLocalLoading(false);
							}
						}}
						sizeVariant="Large"
						colorVariant="Hollow"
					>
						{button.text}
					</RoundedButton>
				</OpacityView>
			))}
		</View>
	);
};
export default memo(FloatingLoginButtons);
