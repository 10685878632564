import { useEffect } from "react";
import { LoginScreenVariant, RenderContentWrapperProps } from "../types";
import useRawLoginStore from "./useRawLoginStore";

const useLoginContainerRegistration = (
	variant: LoginScreenVariant,
	{
		wrapperProps,
	}: {
		wrapperProps?: RenderContentWrapperProps;
	} = {},
) => {
	const store = useRawLoginStore();
	useEffect(() => {
		if (!wrapperProps) return;
		const { registerScreenOverride, deregisterScreenOverride } = store.getState();
		registerScreenOverride(variant, wrapperProps);
		return () => {
			deregisterScreenOverride(variant);
		};
	}, [store, variant, wrapperProps]);
};
export default useLoginContainerRegistration;
