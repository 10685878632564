import { Fragment, useEffect } from "react";
import { View } from "react-native";
import { useSharedValue, withTiming } from "react-native-reanimated";
import { paddingSmall } from "src/styles/spacing";
import GlowProgressBarOrb from "./components/GlowProgressBarOrb";
import GlowProgressBarSegment from "./components/GlowProgressBarSegment/GlowProgressBarSegment";
import { GlowProgressBarProps } from "./types";

const GlowProgressBar = ({
	numHighlighted,
	numTotal,
	width,
	onCompletePressed,
	onIncompletePressed,
	containerStyle,
}: GlowProgressBarProps) => {
	const progressSV = useSharedValue(0);

	useEffect(() => {
		progressSV.value = withTiming(numHighlighted - 1, { duration: 800 });
	}, [progressSV, numHighlighted]);

	return (
		<View
			style={[
				containerStyle,
				{
					width,
					height: 40,
					flexDirection: "row",
					alignItems: "center",
				},
			]}
		>
			{Array.from({ length: numTotal }).map((_, index) => {
				const isEnd = index === numTotal - 1;
				return (
					<Fragment key={index}>
						<GlowProgressBarOrb
							progressSV={progressSV}
							rangeMin={index - 1 + 0.4}
							rangeMax={index - 1 + 1}
							onPress={() => {
								if (progressSV.value > index - 1) {
									onCompletePressed?.(index);
								} else {
									onIncompletePressed?.(index);
								}
							}}
						/>
						{!isEnd && (
							<GlowProgressBarSegment
								progressSV={progressSV}
								rangeMin={index}
								rangeMax={index + 0.4}
								containerStyle={{
									marginHorizontal: paddingSmall,
								}}
							/>
						)}
					</Fragment>
				);
			})}
		</View>
	);
};
export default GlowProgressBar;
