import { Platform, ViewStyle } from "react-native";
import Animated, { interpolate, useAnimatedStyle } from "react-native-reanimated";
import Touchable from "src/components/Touchable";
import { GlowProgressBarOrbProps } from "./types";

const color = "#FFFFFF";
const GlowProgressBarOrb = ({
	onPress,
	size = 16,
	progressSV,
	rangeMin = 0,
	rangeMax = 1,
}: GlowProgressBarOrbProps) => {
	const animatedStyle = useAnimatedStyle<ViewStyle>(() => {
		const radius = interpolate(progressSV.value, [rangeMin, rangeMax], [0, 14], {
			extrapolateLeft: "clamp",
			extrapolateRight: "clamp",
		});
		const shadowOpacity = interpolate(progressSV.value, [rangeMin, rangeMax], [0, 0.8], {
			extrapolateLeft: "clamp",
			extrapolateRight: "clamp",
		});
		const opacity = interpolate(progressSV.value, [rangeMin, rangeMax], [0.5, 1], {
			extrapolateLeft: "clamp",
			extrapolateRight: "clamp",
		});

		if (Platform.OS === "ios") {
			return {
				opacity,
				shadowRadius: radius,
				shadowOpacity: shadowOpacity,
				shadowColor: "rgba(255,255,255)",
			};
		} else if (Platform.OS === "web") {
			return {
				opacity,
				boxShadow: `0px 0px ${radius}px 0px rgba(255,255,255,${shadowOpacity})`,
			};
		}
		return {};
	}, [progressSV, rangeMin, rangeMax]);

	return (
		<Touchable animation="None" onPress={onPress} hitSlop={20}>
			<Animated.View
				style={[
					{
						backgroundColor: color,
						width: size,
						height: size,
						borderRadius: size / 2,
					},
					animatedStyle,
				]}
			/>
		</Touchable>
	);
};
export default GlowProgressBarOrb;
