import { ZustandSelector } from "src/types/zustand";
import { useStore } from "zustand";
import { LoginStore } from "../stores/loginStore";
import useRawLoginStore from "./useRawLoginStore";

const useLoginStore = <U extends any>(selector: ZustandSelector<LoginStore, U>): U => {
	const store = useRawLoginStore();
	return useStore(store, selector);
};
export default useLoginStore;
