import { StyleProp, View, ViewStyle } from "react-native";
import InlineLinkText from "src/components/InlineLinkText";
import config from "src/config";
import { paddingMedium } from "src/styles/spacing";

const TermsOfService = ({ style }: { style?: StyleProp<ViewStyle> }) => {
	return (
		<View
			style={[
				{
					flexDirection: "row",
					maxWidth: 300,
				},
				style,
			]}
		>
			<InlineLinkText
				containerStyle={{
					marginBottom: paddingMedium,
					flex: 1,
				}}
				textProps={{
					xs: true,
					color: "TextLight",
				}}
				linkTextProps={{
					xs: true,
					color: "Text",
					semibold: true,
				}}
			>
				By signing up to swsh, you confirm that you agree to our [terms of service](
				{config.share.base}/terms) and [privacy policy]({config.share.base}/policy)
			</InlineLinkText>
		</View>
	);
};

export default TermsOfService;
