import { useMemo } from "react";
import { View } from "react-native";
import { Link, Text } from "src/swsh-native";
import CompoundText from "../CompoundText";
import parseInlineLinkText from "./helpers/parseInlineLinkText";
import { InlineLinkTextProps } from "./types";

const InlineLinkText = ({
	children,
	containerStyle,
	linkTextProps,
	textProps,
}: InlineLinkTextProps) => {
	const parts = useMemo(() => {
		if (!children || children.length === 0) return [];
		return parseInlineLinkText(Array.isArray(children) ? children.join("") : children);
	}, [children]);

	return (
		<View
			style={[
				{
					flexDirection: "row",
					flexWrap: "wrap",
					justifyContent: "flex-start",
					alignItems: "center",
				},
				containerStyle,
			]}
		>
			{parts.length === 1 && parts[0]?.type === "text" ? (
				// No need to wrap in CompoundText if there's only one text part
				<Text {...textProps}>{parts[0]?.text}</Text>
			) : (
				parts.map((part, index) => {
					switch (part.type) {
						case "text":
							return (
								<CompoundText key={index} {...textProps}>
									{part.text}
								</CompoundText>
							);
						case "link":
							return (
								<Link key={index} href={part.url} target="_blank">
									<Text {...linkTextProps}>{part.text}</Text>
								</Link>
							);
					}
				})
			)}
		</View>
	);
};
export default InlineLinkText;
