import { View } from "react-native";
import { LoginLazyPrefetcherProps } from "./types";

const LoginLazyPrefetcher = ({ spec }: LoginLazyPrefetcherProps) => {
	return (
		<View
			style={{
				position: "absolute",
				display: "none",
			}}
		>
			{spec.screens.map((screen) => {
				return <screen.Component key={screen.variant} noop={true} />;
			})}
		</View>
	);
};
export default LoginLazyPrefetcher;
