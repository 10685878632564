import { MutableRefObject, useCallback } from "react";
import isMaestro from "src/constants/isMaestro";
import isPlaywright from "src/constants/isPlaywright";
import useStateRef from "src/hooks/useStateRef";

const isOtpValid = (otp: string) =>
	otp.length === 6 ||
	(otp.length > 6 && isPlaywright) ||
	// Test password is 13 characters long -- Maestro enters characters one at a time, so we want to wait for the full password entry
	// Also the test password isn't particularly secretive, so don't worry about protecting the length of it here
	(otp.length === 13 && isMaestro);
const useOtpInput = ({
	otpValueRef,
	onValidOtp,
}: {
	otpValueRef: MutableRefObject<string>;
	onValidOtp: (otp: string) => void;
}) => {
	const [isValid, setIsValid, isValidRef] = useStateRef(false);

	const onOtpChangeText = useCallback(
		(text: string) => {
			otpValueRef.current = text;
			const shouldBeValid = isOtpValid(text);
			if (isValidRef.current !== shouldBeValid) {
				setIsValid(shouldBeValid);
			}
			if (shouldBeValid) {
				onValidOtp(text);
			}
		},
		[isValidRef, onValidOtp, otpValueRef, setIsValid],
	);

	return {
		isValid,
		onOtpChangeText,
	};
};
export default useOtpInput;
