const parseInlineLinkText = (text: string) => {
	const blurbParts: (
		| {
				type: "text";
				text: string;
		  }
		| {
				type: "link";
				text: string;
				url: string;
		  }
	)[] = [];
	for (const part of text.split(/(\[.*?\]\(.*?\))/)) {
		if (part.startsWith("[") && part.endsWith(")")) {
			const linkParts = part.match(/\[(.*?)\]\((.*?)\)/);
			const text = linkParts?.[1];
			const url = linkParts?.[2];
			if (text && url) {
				blurbParts.push({
					type: "link",
					text,
					url,
				});
			}
		} else {
			const text = part.trimEnd();
			if (text) {
				blurbParts.push({
					type: "text",
					text,
				});
			}
		}
	}
	return blurbParts;
};
export default parseInlineLinkText;
