import { useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import usePrefetch from "../usePrefetch/usePrefetch";
import { UsePrefetchCallback } from "./types";

const usePrefetchCallback: UsePrefetchCallback = (options = {}) => {
	const searchParams = useSearchParams();
	const [url, setUrl] = useState<string | null>(null);

	useEffect(() => {
		const callback = searchParams.get("callback");
		if (callback) {
			setUrl(callback);
		}
	}, [searchParams]);

	usePrefetch(url, options);
};
export default usePrefetchCallback;
