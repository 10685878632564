import Text, { TextProps } from "../../swsh-native/Text/Text";

const CompoundText = ({
	children,
	...textProps
}: {
	children: string;
} & TextProps) => {
	const text = children.split(" ");
	return (
		<>
			{text.map((word, index) => (
				<Text key={index} {...textProps}>
					{word}{" "}
				</Text>
			))}
		</>
	);
};

export default CompoundText;
