import { MutableRefObject, createContext, useContext } from "react";
import usePhoneNumberInput from "../hooks/usePhoneNumberInput";

export interface LoginScreenContextType {
	inputVariant: "Phone" | "Email";
	setInputVariant: (variant: "Phone" | "Email") => void;
	phoneNumberInputProps: Omit<ReturnType<typeof usePhoneNumberInput>, "isLikelyValid">;
	emailInputProps: {
		emailValueRef: MutableRefObject<string>;
		onDebouncedEmailChange: (email: string) => void;
		onSubmit: () => void;
	};
}

const LoginScreenContext = createContext<LoginScreenContextType | null>(null);
export default LoginScreenContext;

export const useLoginScreenContext = () => {
	const context = useContext(LoginScreenContext);
	if (!context) {
		throw new Error("useLoginScreenContext must be used within a LoginScreenContextProvider");
	}
	return context;
};
