import { MutableRefObject, useCallback, useRef, useState, useTransition } from "react";
import { Platform } from "react-native";
import isMaestro from "src/constants/isMaestro";
import isTestUser from "src/constants/isTestUser";
import { TEST_PHONE_NUMBER_PREFIX } from "src/constants/testConstants";
import useStateRef from "src/hooks/useStateRef";
import { TextInputHandler } from "src/swsh-native";
import rawPhoneLookup from "./constants/rawPhoneLookup";

const isTestPhoneNumber = (phoneNumber: string) =>
	phoneNumber.startsWith(TEST_PHONE_NUMBER_PREFIX) && isTestUser;

interface CountryInfo {
	name: string | null;
	isoCode: string | null;
	intlCode: string | null;
	format?: string;
	exactFormat: boolean;
}
const getCountryInfo = (item: any) =>
	({
		name: item[0],
		isoCode: item[2],
		intlCode: item[3],
		format: item[4] ?? "xxxxxxxxxxxxxxx",
		exactFormat: !!item[4],
	}) as CountryInfo;
const USInfo = getCountryInfo(rawPhoneLookup[0]) as {
	name: string;
	isoCode: string;
	intlCode: string;
	format: string;
	exactFormat: boolean;
};

/**
 * Takes in some weirdly formatted phone number and tries to match it to a country code
 * Aims to handle the following cases:
 * +12035006332
 * 12035006332
 * 2035006332
 * (203) 500-6332
 */
const bestMatchCountryInfo = (phoneNumber: string) => {
	const rawPhoneNumber = phoneNumber.replace(/\D/g, "");
	/**
	 * Assuming anything with 10 characters is a US number because American exceptionalism lol
	 */
	if (!phoneNumber.startsWith("+") && rawPhoneNumber.length === 10) {
		return {
			phoneNumber: rawPhoneNumber,
			countryCode: USInfo.intlCode,
			info: USInfo,
		};
	}

	for (let i = 0; i < 3; i += 1) {
		const intlCodeCandidate = rawPhoneNumber.slice(0, i + 1);
		const phoneNumberCandidate = rawPhoneNumber.slice(i + 1);
		for (const rawCountry of rawPhoneLookup) {
			const countryInfo = getCountryInfo(rawCountry);
			if (countryInfo.intlCode !== intlCodeCandidate) continue;
			const digitLen = countryInfo.format?.replace(/[^x]/g, "").length;
			if (!digitLen) continue;
			if (phoneNumberCandidate.length !== digitLen) continue;
			return {
				phoneNumber: phoneNumberCandidate,
				countryCode: intlCodeCandidate,
				info: countryInfo,
			};
		}
	}
	return null;
};

const usePhoneNumberInput = ({
	phoneNumberValueRef,
	onValidPhoneNumber,
}: {
	/**
	 * Populated with e164
	 */
	phoneNumberValueRef?: MutableRefObject<string>;
	onValidPhoneNumber?: (phoneNumber: string) => void;
} = {}) => {
	const countryCodeInputRef = useRef<TextInputHandler>(null);
	const [_, startTransition] = useTransition();
	const curCountryCode = useRef(USInfo.intlCode);
	const curPhoneNumber = useRef("");
	const [formattedPhoneNumber, setFormattedPhoneNumber] = useState<
		string | undefined
		// Web doesn't like switching between controlled and uncontrolled inputs
	>(Platform.OS === "web" ? "" : undefined);
	const [shouldUseControlledInput, setShouldUseControlledInput] = useState(
		// For native we decide to use controlled input based on paste
		Platform.OS === "web" ? true : false,
	);
	const [format, setFormat] = useState(USInfo.format);
	const [isLikelyValid, setIsLikelyValid, isLikelyValidRef] = useStateRef(false);

	const getCurSelectedCountry = useCallback(() => {
		const foundCountryInfo = rawPhoneLookup.find(
			(item: any, index: number) => item[3] === curCountryCode.current,
		);
		if (!foundCountryInfo) return undefined;
		return getCountryInfo(foundCountryInfo);
	}, []);

	const handleReformatPhoneNumber = useCallback(() => {
		const phoneNumber = curPhoneNumber.current;
		if (isTestPhoneNumber(phoneNumber)) {
			setFormattedPhoneNumber(phoneNumber);
			return;
		}
		const selectedCountry = getCurSelectedCountry();
		if (!selectedCountry || !selectedCountry?.format) {
			setFormattedPhoneNumber(phoneNumber);
			return;
		}
		setFormat(selectedCountry.format);

		let phoneNumberIndex = 0;
		let formatted = "";
		for (
			let i = 0;
			i < selectedCountry.format.length && phoneNumberIndex < phoneNumber.length;
			i++
		) {
			if (selectedCountry.format[i] === "x") {
				if (phoneNumber[phoneNumberIndex]) {
					formatted += phoneNumber[phoneNumberIndex++];
				}
			} else {
				formatted += selectedCountry.format[i];
			}
		}
		setFormattedPhoneNumber(formatted);
	}, [getCurSelectedCountry]);

	const handleUpdatePhoneNumber = useCallback(
		(existingMatch?: ReturnType<typeof bestMatchCountryInfo>) => {
			const shouldBeValid = !(
				(curCountryCode.current === "1" && curPhoneNumber.current.length !== 10) ||
				(curCountryCode.current.length === 0 && curPhoneNumber.current.length < 6)
			);
			if (shouldBeValid !== isLikelyValidRef.current) {
				setIsLikelyValid(shouldBeValid);
			}
			if (shouldBeValid) {
				const match =
					existingMatch ??
					bestMatchCountryInfo(`+${curCountryCode.current}${curPhoneNumber.current}`);
				if (
					match &&
					`+${match.countryCode}${match.phoneNumber}` !== phoneNumberValueRef?.current
				) {
					onValidPhoneNumber?.(`+${match.countryCode}${match.phoneNumber}`);
					setShouldUseControlledInput(true);
				}
			}
			if (phoneNumberValueRef) {
				phoneNumberValueRef.current = `+${curCountryCode.current}${curPhoneNumber.current}`;
			}
			startTransition(handleReformatPhoneNumber);
		},
		[
			phoneNumberValueRef,
			isLikelyValidRef,
			handleReformatPhoneNumber,
			setIsLikelyValid,
			onValidPhoneNumber,
		],
	);

	const handlePhoneNumberPasted = useCallback(
		(text: string) => {
			const match = bestMatchCountryInfo(text);
			if (match) {
				const { phoneNumber, countryCode } = match;
				curPhoneNumber.current = phoneNumber;
				curCountryCode.current = countryCode;
				handleUpdatePhoneNumber(match);
				countryCodeInputRef.current?.setValue(`+${countryCode}`);
			} else {
				curPhoneNumber.current = text.replace(/\D/g, "");
				handleUpdatePhoneNumber();
			}
		},
		[handleUpdatePhoneNumber],
	);

	const onCountryCodeChangeText = useCallback(
		(text: string) => {
			curCountryCode.current = text.replace(/\D/g, "");
			handleUpdatePhoneNumber();
		},
		[handleUpdatePhoneNumber],
	);

	const prevValue = useRef("");
	const handlePhoneNumberChangeText = useCallback(
		(text: string) => {
			if (isTestPhoneNumber(text)) {
				// Maestro enters characters one at a time, so we wait until the confirmation code "MAESTRO" is entered
				// It's a very ugly solution, but the goal is just to get Maestro signed in
				if (isMaestro) {
					if (!text.endsWith("MAESTRO")) return;
					text = text.slice(0, -"MAESTRO".length);
				}
				curPhoneNumber.current = text;
				setFormattedPhoneNumber(text);
				setIsLikelyValid(true);
				onValidPhoneNumber?.(text);
				return;
			} else if (text.length - prevValue.current.length > 1) {
				handlePhoneNumberPasted(text);
			} else {
				curPhoneNumber.current = text.replace(/\D/g, "");
				handleUpdatePhoneNumber();
			}
		},
		[handlePhoneNumberPasted, handleUpdatePhoneNumber, onValidPhoneNumber, setIsLikelyValid],
	);

	const onPhoneNumberChangeText = useCallback(
		(text: string) => {
			handlePhoneNumberChangeText(text);
			prevValue.current = text;
		},
		[handlePhoneNumberChangeText],
	);

	return {
		countryCodeInputRef,
		formattedPhoneNumber: shouldUseControlledInput ? formattedPhoneNumber : undefined,
		format,
		onCountryCodeChangeText,
		onPhoneNumberChangeText,
		isLikelyValid,
		curCountryCode,
		curPhoneNumber,
	};
};

export default usePhoneNumberInput;
