import { useCallback } from "react";
import { useSearchParams } from "solito/navigation";
import log from "src/helpers/log";
import sanitizeCallback from "src/helpers/sanitizeCallback";
import useRouter from "src/hooks/useRouter";
import { UseCallbackRedirect } from "./types";

const useCallbackRedirect: UseCallbackRedirect = () => {
	const router = useRouter();
	const searchParams = useSearchParams<{ callback?: string }>();
	const handleCallbackDirect = useCallback(
		({ fallback = "/home" }: { fallback?: string } = {}) => {
			const originalCallback = searchParams?.get("callback");
			const callback = sanitizeCallback(originalCallback);
			if (callback) {
				router.replace(callback);
			} else {
				// We only want to error if there is no callback; not if the callback is sanitized invalid
				if (!originalCallback) {
					log.error("No callback found in useCallbackRedirect");
				}
				router.replace(fallback);
			}
		},
		[router, searchParams],
	);
	return handleCallbackDirect;
};
export default useCallbackRedirect;
